module.exports = [{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-178467742-3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
