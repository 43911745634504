import './src/css/main.css'

/* eslint-disable */
if (process.env.NODE_ENV === 'production') {
  globalThis._oad = [{ customer: 225, page: 'nextbike', checksum: '4ceb5f' }]
  let _oad = [{ customer: 225, page: 'nextbike', checksum: '4ceb5f' }]
  !(function (e, t, a, c, n) {
    ;(c = e.createElement(t)),
      (c.async = 1),
      (c.src = a),
      (n = e.getElementsByTagName(t)[0]),
      n.parentNode.insertBefore(c, n)
  })(
    document,
    'script',
    'https://ad.ad-srv-track.com/track_js.php?t=' + Math.random().toString().substring(5, 15)
  )
}
/* eslint-enable */
